import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Button, ProgressBar, Spinner } from 'react-bootstrap';
import question from './questions'
import { Routes } from 'common';
import API from 'services/API'
import './Style.css'


export class FormFields extends Component {
  constructor(props){
		super(props);
		this.state={
			progress: 0,
			activePage: 0,
			questions: [],
			isLoading: false,
			selectedQuestions: []
		}
	}
	componentDidMount(){
		this.retrieve()
	}
	retrieve(){
		let tempArray = []
		this.setState({isLoading: true})
		let params = {
			sort: {
				id: 'asc'
			}
		}
		API.request(Routes.retrieveQuestions, params, response => {
			this.setState({isLoading: false})
			if(response.data.length > 0){
				for (let i = 0; i <= response.data.length-1; i++) {
					const element = response.data[i];
					tempArray.push(JSON.parse(element.questions))
				}
				this.setState({questions: tempArray})
			}
		})
	}
	handleSelect(method, item, selectedBtn){
		const {progress, activePage, questions} = this.state
		let finishedPage = activePage + 1
		let total = questions.length
		console.log('========', finishedPage);
		if(finishedPage >= questions.length){
			item.options[item.options.indexOf(selectedBtn)].selected = true
			item.options.filter(el => {
				el.selected = el.title === selectedBtn.title 
			})
			questions[questions.indexOf(item)] = item
			this.setState({questions: questions})

			let obj = []
			questions.map(each => {
				let params = {
					question: each.question
				}
				let selected = each.options.filter(el => {return el.selected === true})
				params['selected'] = selected[0].title
				obj.push(params)
			})
			console.log('==?????????', obj);
			this.setState({selectedQuestions: obj})
			let apiParams = {
				pc: navigator.appVersion,
				answers: JSON.stringify(obj),
			}
			API.request(Routes.createAnswers, apiParams, response => {
				if(response.data !== null){
					this.props.handleFinish()
				}
			})
		}else{
			if(method === 'next'){
				this.setState({progress: parseInt((finishedPage / total) * 100), activePage: activePage + 1})
				item.options[item.options.indexOf(selectedBtn)].selected = true
				item.options.filter(el => {
					el.selected = el.title === selectedBtn.title 
				})
				questions[questions.indexOf(item)] = item
				this.setState({questions: questions})
			}else{
				this.setState({progress: parseInt(((activePage - 1) * 100) / total), activePage: activePage - 1})	
			}
		}
	}
	formComponent = (form) => (
		<div>
			<h5 style={{color: 'black', marginBottom: '30px', fontWeight: 'bold'}}>{form.question}</h5>
			<div className="d-grid gap-2">
				{
					form?.options?.map((item, index) => (
							<Button
								key={index}
								variant={item.selected ? 'danger' : "outline-danger"}
								size="lg"
								onClick={() => this.handleSelect('next', form, item)}>
								{item.title}
							</Button>
						)
					)
				}
			</div>
		</div>
	)
	renderQuestions(){
		const {activePage, questions} = this.state
		return (
			<div>
				{this.formComponent(questions[activePage])}
				<div style={{display: 'flex', marginTop: '15%', justifyContent: 'space-between', alignItems: 'center'}}>
					{
						activePage > 0 && (
							<FontAwesomeIcon icon={faCircleArrowLeft} style={{color: 'blue'}} onClick={() => this.handleSelect('prev', null, null)}/>
						)
					}
					<p style={{
						fontSize: '10px',
						color: 'black',
						verticalAlign: 'middle'
					}}>{questions[activePage].type}</p>
				</div>
			</div>
		)
	}


  render() {
	const {progress, activePage, isLoading, questions} = this.state;
    return (
      <div className='form'>
			{
				isLoading && questions.length <= 0 && (
					<Spinner animation="border" variant="primary"/>
				)
			}
			{
				!isLoading && questions.length > 0 && (
					<div>
						<ProgressBar now={progress}/>
						<div style={{width: '100%',textAlign: 'left'}}>
							<p style={{color: 'black', fontSize: '10px'}}>{progress}% complete</p>
						</div>
						<div style={{width: '100%'}}>
							{
								activePage <= questions.length - 1 && !isLoading && questions.length > 0 && (
									this.renderQuestions()
								)
							}
						</div>
					</div>
				)
			}
		</div>
    )
  }
}

export default FormFields