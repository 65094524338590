import axios from "axios";
import CONFIG from 'config'
const API = {
    request: (endpoint, parameter, callback, errorCallback=null) => {
        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(parameter)
        }
        fetch(CONFIG.API_URL + endpoint, fetchOptions).then(response => response.json()).then(json => {
            callback(json)
        }).catch(error => {
            if(errorCallback){
                errorCallback(error)
            }
        })
    }
}

export default API