import { Button } from 'react-bootstrap'
import React, { Component } from 'react'
import './Style.css'
import FormFields from '../forms/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export class Form extends Component {
	constructor(props){
		super(props);
		this.state={
			page: null,
			startSurvey: false,
			finishSurvey: false,
			timeout: false
		}
	}
	componentDidMount(){
		console.log('=========', navigator.appVersion);
	}
	handleFinish(){
		this.setState({finishSurvey: true, startSurvey: false})
	}
	renderWelcome = () => (
		<div>
			<h1 style={{color: 'blue'}}>MISSOURI MIDTERM POL</h1><br/>
			<div style={{color: 'black'}}>
				<b><p>Dear Missouri Resident</p></b>
				<b><p>If you plan on voting in the Fall Midterms, we want to hear your opinion! This poll
captures no personal information, and your answers will remain anonymous.</p></b>
				<p>
				If you don’t plan on voting, or are ineligible to vote, you may still participate, but just mark
 the voting question accordingly. Thank you for your participation and your valued
 opinion!
				</p>
				<p>Click the button below to start.</p>
				<div className="d-grid gap-2">
					<Button variant="danger" size="lg" onClick={() => this.setState({startSurvey: true})}>
						Start Survery
					</Button>
				</div>
			</div>
		</div>
	)
	renderFinishPage(){
		return (
			<div style={{paddingTop: '10%'}}>
				<FontAwesomeIcon icon={faCircleCheck} style={{color: 'blue', fontSize: '180px', marginBottom: '30px'}} />
				<div style={{color: 'black'}}>
					<h1 style={{fontWeight: 'bolder'}}>SURVEY COMPLETED</h1>
					<p>Thanks for taking our survey, based on your answers, we recommend chaking out Eric Grietens!</p>
				</div>
			</div>
		)
	}
	renderFinalPage1(){
		return (
			<div>
				<h1 style={{color: 'blue', fontWeight: 'bold'}}>Based on your answers...</h1>
				<div style={{display: 'flex', marginTop: '4%'}}>
					<div style={{color: 'black'}}>
						<p style={{fontWeight: 'bold', fontSize: '20px'}}>We recommend Eric Greitens for Missouri Senator!</p>
						<p style={{fontSize: '20px'}}>Do you know the true story behind the corrupt political takedown of Navy
							SEAL Gov. Eric Greitens? Do-nothing RINO’s and extremist Liberals used the mainstream media to orchestrate a take down of Eric Greitens, but he is still fighting for Missouri.</p><br/>
						<p style={{fontSize: '20px'}}>To learn the true story, and to see what’s at stake in the midterm elections, click the button below!</p><br/>
						<div className="d-grid gap-2" style={{marginTop: '10%'}}>
							<Button variant="danger" size="lg" onClick={() => {
								window.location.href = 'https://www.ericgreitens.com/'
							}}>
								Take Actions!
							</Button>
						</div>
					</div>
					<img src={require('assets/eric.png')} className="image"></img>
				</div>
			</div>
		)
	}
	renderFinalPage2(){
		return (
			<div>
				<h1 style={{color: 'blue', fontWeight: 'bold'}}>Based on your answers...</h1>
				<p style={{fontWeight: 'bold', fontSize: '20px', color: 'black'}}>We recommend Eric Greitens for Missouri Senator!</p>
				<img src={require('assets/eric.png')} className="image"></img>
				<div style={{color: 'black'}}>
					<p style={{fontSize: '20px'}}>Do you know the true story behind the corrupt political takedown of Navy
						SEAL Gov. Eric Greitens? Do-nothing RINO’s and extremist Liberals used the mainstream media to orchestrate a take down of Eric Greitens, but he is still fighting for Missouri.</p><br/>
					<p style={{fontSize: '20px'}}>To learn the true story, and to see what’s at stake in the midterm elections, click the button below!</p><br/>
					<div className="d-grid gap-2" style={{marginTop: '10%'}}>
						<Button variant="danger" size="lg" onClick={() => {
							window.location.href = 'https://www.ericgreitens.com/'
						}}>
							Take Actions!
						</Button>
					</div>
				</div>
			</div>
		)
	}
	render() {
		const {page, startSurvey, finishSurvey, timeout} = this.state
		if(finishSurvey === true){
			setTimeout(() => {
				this.setState({timeout: true})
			}, 5000)
		}
		return (
			<div className='card'>
				{	
					startSurvey === false && finishSurvey === false && (
						this.renderWelcome()
					)
				}
				{
				startSurvey === true && finishSurvey === false && (
					<FormFields handleFinish={() => this.handleFinish()}/>
				)}
				{
					finishSurvey === true && finishSurvey === true && timeout===false &&(
						this.renderFinishPage()
					)
				}
				{
					finishSurvey === true && finishSurvey === true && timeout===true && (
						<section>
							<div className='web'>
								{this.renderFinalPage1()}
							</div>
							<div className='mobile'>
								{this.renderFinalPage2()}
							</div>
						</section>
					)
				}
			</div>
		)
	}
}

export default Form